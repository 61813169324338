import React, { useEffect } from 'react';
import Layout from '@components/layout/layout';
import { graphQLClient } from '../utils/graphql-api';
import { PopularCategories } from '@components/main/blocks/popular-categories/popular-categories';
import { ProductCategories } from '@components/main/blocks/product-categories/product-categories';
import { Wrapper } from '@components/ui/wrapper/wrapper.style';
import { MainVideos } from '@components/main/blocks/videos/videos';
import { LastViewedProducts } from '@components/blocks/last-viewed-products/last-viewed-products';
import { CommonBlockWithTitle } from '@components/blocks/common-block-with-title';
import { FAQ } from '@components/blocks/faq/faq';
import { TagsBlock } from '@components/blocks/tags-block/tags-block';
import { PageIndexDocument, PageIndexQuery } from '../gql/generated';
import { GetServerSideProps } from 'next';
import { CitiesBlock } from '@components/blocks/cities-block/tags-block';
import { OurProjects } from '@components/main/blocks/our-projects/our-proejcts';
import { MarketWidget } from '@components/MarketWidget';
import { useStore } from '@stores/root';
import { CpaWidget } from '@components/CpaWidget';
import { WH } from '@components/WH';

export const getServerSideProps: GetServerSideProps = async ({ req, res, resolvedUrl }) => {
  const pageDataQuery = graphQLClient(req.headers).query({
    query: PageIndexDocument,
    context: {
      headers: {
        'update-cache': req.headers['update-cache'] ? 'true' : 'false',
      },
    },
  });

  const pageData = await pageDataQuery;

  return {
    props: {
      ...pageData.data,
    },
  };
};

type Props = PageIndexQuery;

const IndexPage = (props: Props) => {
  const { project } = useStore();
  return (
    <Layout showHeaderBanner hidePreFooterBlock>
      <Wrapper>
        <div style={{ marginBottom: '80px' }}>
          <CpaWidget searchText={props.tags[1].text} name="main-page" />
        </div>

        {props.popularCategories.length >= 8 && (
          <PopularCategories categories={props.popularCategories} />
        )}

        <ProductCategories
          disabledPopularCategories={props.popularCategories.length < 8}
          categories={props.popularCategories}
          listKey="mainPageProductCategories"
        />
      </Wrapper>

      {props.externalRelink && <OurProjects relink={props.externalRelink} />}

      <Wrapper>
        <MainVideos videos={props.getVideos.items} />

        <CommonBlockWithTitle>
          <LastViewedProducts />
        </CommonBlockWithTitle>

        <CommonBlockWithTitle>
          <FAQ questions={props.getPopularKeywords.slice(0, 8)} />
        </CommonBlockWithTitle>

        {props.tags && props.tags.length > 0 && (
          <TagsBlock canShowMore limit={10} title="Смотрите также" tags={props.tags} isRelink />
        )}

        <WH>
          <CitiesBlock title="Города" tags={props.getTopCityList} />
        </WH>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;
