import { StyledPopularCategory } from '@components/main/elements/popular-category/popular-category.style';
import { BlockTitle } from '@components/ui/block-title/block-title';
import { device, scrollbar, theme } from '@components/ui/theme';
import styled from 'styled-components';
import { IconButton } from '@components/ui/icon-button/icon-button';
import { PopularCategory } from '@components/main/elements/popular-category/popular-category';
import { TitleResponsiveInfo } from '@components/ui/block-title/block-title.style';

export const PopularCategoriesContent = styled.div`
  margin-top: 40px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
`;

export const PopularCategoriesContentWrapper = styled.div`
  @media (max-width: 1050px) {
    overflow-x: scroll;
    ${scrollbar.disable}
    width: calc(100% + 60px);
    position: relative;
    left: -30px;
  }

  @media (max-width: 600px) {
    width: calc(100% + 40px);
    position: relative;
    left: -20px;

    ::-webkit-scrollbar-thumb {
      border-left: 20px white solid;
      border-right: 20px white solid;
    }
  }

  @media (max-width: 480px) {
    ${scrollbar.disable}
  }
`;

export const PopularCategoriesShowMoreButton = styled.a`
  display: none;

  button {
    height: 40px;
    width: 100%;
    font-weight: 400;
    text-transform: none;
    font-size: 14px;
    color: ${theme.colors.black} !important;
    background-color: #f9f7fa !important;
    letter-spacing: unset;
  }

  @media (max-width: 480px) {
    display: block;
  }
`;

export const CategoriesSliderContainer = styled.div`
  position: relative;

  .swiper {
    padding: 0;
    box-shadow: 0px 2px 12px rgba(99, 47, 117, 0.14);
  }
`;

export const PrevCategory = styled(IconButton)`
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(99, 47, 117, 0.09);
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -22px;
  margin: auto;
  z-index: 2;

  &:disabled {
    display: none;
  }

  &:hover:not(:disabled) {
    color: ${theme.colors.primary};
  }
`;

export const NextCategory = styled(IconButton)`
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(99, 47, 117, 0.09);
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -22px;
  margin: auto;
  z-index: 2;

  &:disabled {
    display: none;
  }

  &:hover:not(:disabled) {
    color: ${theme.colors.primary};
  }
`;

export const PopularCategoriesSlide = styled.div`
  padding: 40px 35px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  gap: min(calc((1440px - 278px * 4) / 3), calc((100vw - 278px * 4) / 3));
  background-color: #ffffff;
`;

export const PopularCategoriesSlideImage = styled.div`
  margin-right: 40px;
  width: 70px;
  height: 70px;
  position: relative;

  img,
  span {
    width: 70px !important;
    height: 70px !important;
    min-width: 70px !important;
    max-width: 70px !important;
    min-height: 70px !important;
    max-height: 70px !important;
  }
`;

export const PopularCategoriesSlideTitle = styled.h3`
  font-size: 16px;
  line-height: 140%;
  font-weight: 400;
  color: ${theme.colors.black};
`;

export const PopularCategoriesSlideItem = styled.a`
  flex-basis: 276px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    border-right: 1px solid #f4f2f5;
  }

  &:hover ${PopularCategoriesSlideTitle} {
    color: ${theme.colors.primary};
  }
`;

export const StyledPopularCategories = styled.section<{ count: number }>`
  //margin-top: 80px;

  ${TitleResponsiveInfo} {
    display: none;
  }

  @media (max-width: 1396px) {
    ${PopularCategoriesContent} {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 1050px) {
    ${PopularCategoriesContent} {
      margin-top: 24px;
      padding-bottom: 22px;
      padding-left: 30px;
      padding-right: 30px;
      gap: 20px;
      max-height: calc(182px * 2 + 20px + 25px);
      display: flex;
      flex-wrap: wrap;
      width: calc((36vw + 30px) * ${(props) => props.count});

      ${NextCategory}, ${PrevCategory} {
        display: none;
      }
    }

    ${PopularCategory}, ${StyledPopularCategory} {
      width: 36vw;
      height: 180px;
    }
  }

  @media (max-width: 1000px) {
    ${TitleResponsiveInfo} {
      display: flex;
      padding-bottom: 4px;
      a {
        font-size: 13px;
      }
    }
  }

  @media (max-width: 961px) {
    ${PopularCategoriesContent} {
      width: calc((38vw + 30px) * ${(props) => props.count});
    }

    ${PopularCategory}, ${StyledPopularCategory} {
      width: 38vw;
    }
  }

  @media (max-width: 823px) {
    ${PopularCategoriesContent} {
      width: calc((42vw + 30px) * ${(props) => props.count});
    }

    ${PopularCategory}, ${StyledPopularCategory} {
      width: 42vw;
    }
  }

  @media (max-width: 769px) {
    ${PopularCategoriesContent} {
      width: calc((49vw + 30px) * ${(props) => props.count});
    }

    ${PopularCategory}, ${StyledPopularCategory} {
      width: 49vw;
    }
  }

  @media (max-width: 631px) {
    ${PopularCategoriesContent} {
      width: calc((54vw + 30px) * ${(props) => props.count});
    }

    ${PopularCategory}, ${StyledPopularCategory} {
      width: 54vw;
    }
  }

  @media (max-width: 573px) {
    ${PopularCategoriesContent} {
      width: calc((60vw + 30px) * ${(props) => props.count});
    }

    ${PopularCategory}, ${StyledPopularCategory} {
      width: 60vw;
    }
  }

  @media (max-width: 513px) {
    ${PopularCategoriesContent} {
      width: calc((67vw + 30px) * ${(props) => props.count});
    }

    ${PopularCategory}, ${StyledPopularCategory} {
      width: 67vw;
    }
  }

  @media ${device.tabletM} {
    margin-top: 0;
  }

  @media (max-width: 600px) {
    margin-top: 0;

    ${PopularCategoriesContent} {
      padding: 0 20px 25px 20px;
    }
  }

  @media ${device.mobileM} {
    ${BlockTitle} {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }

    ${TitleResponsiveInfo} {
      padding-bottom: 2px;

      a {
        text-decoration: none;
      }
    }

    ${PopularCategoriesContent} {
      margin: 25px 0;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 0;
      column-gap: 10px;
      row-gap: 20px;
      max-height: calc(151px * 2 + 16px + 20px);
      width: calc((162px + 16px) * ${(props) => props.count});
    }

    ${PopularCategory}, ${StyledPopularCategory} {
      width: 160px;
    }
  }

  @media (max-width: 380px) {
    ${PopularCategoriesContent} {
      width: calc((142px + 16px) * ${(props) => props.count});
    }

    ${PopularCategory}, ${StyledPopularCategory} {
      width: 140px;
    }
  }
`;
