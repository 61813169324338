import React from 'react';
import Link from 'next/link';
import * as S from './popular-categories.style';
import { BlockTitle } from '@components/ui/block-title/block-title';
import { PopularCategory } from '@components/main/elements/popular-category/popular-category';
import plural from 'plural-ru';
import { PopularCategoryLink } from '@components/main/elements/popular-category/popular-category-link';
import { PageCartQuery, PageIndexQuery } from '../../../../gql/generated';
import { useStore } from '@stores/root';
import { Button } from '@components/ui/button/button';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';

type Props = {
  categories: PageIndexQuery['popularCategories'] | PageCartQuery['popularCategories'];
};

export const PopularCategories = observer((props: Props) => {
  const { project, ui } = useStore();
  const router = useRouter();

  const countPlural = plural(
    project.categories.length,
    '%d категория',
    '%d категории',
    '%d категорий'
  );

  const countLength = () => {
    const count = Math.floor(props.categories.length / 2);
    const isEven = count % 2 === 0;

    if (!isEven) return count + 1;

    return count;
  };
  const goToCatalogue = () => router.push('/catalog');

  return (
    <S.StyledPopularCategories count={countLength()}>
      <BlockTitle
        link={ui.viewport <= 1000 ? { title: countPlural, href: '/catalog' } : null}
        subtitle={ui.viewport > 1000 ? countPlural : ''}
        subtitleLink="/catalog"
      >
        Популярные категории
      </BlockTitle>
      <S.PopularCategoriesContentWrapper>
        <S.PopularCategoriesContent>
          {ui.viewport > 480
            ? props.categories
                .slice(0, 11)
                .map(
                  (category) =>
                    category.productsCount > 0 && (
                      <PopularCategory key={category.id} {...category} />
                    )
                )
            : props.categories.map(
                (c) => c.productsCount > 0 && <PopularCategory key={c.id} {...c} />
              )}
          <Link legacyBehavior prefetch={false} href="/catalog" passHref>
            <PopularCategoryLink
              offersCount={project.countOffers}
              productsCount={project.productsCount}
            />
          </Link>
        </S.PopularCategoriesContent>
      </S.PopularCategoriesContentWrapper>

      <Link legacyBehavior prefetch={false} href="/catalog" passHref>
        <S.PopularCategoriesShowMoreButton>
          <Button onClick={goToCatalogue} secondary>
            Смотреть все
          </Button>
        </S.PopularCategoriesShowMoreButton>
      </Link>
    </S.StyledPopularCategories>
  );
});
