import { Wrapper } from '@components/ui/wrapper/wrapper.style';
import React from 'react';
import * as S from './our-projects.style';
import { OurProject } from '@components/main/elements/our-project/our-project';
import { BlockTitle } from '@components/ui/block-title/block-title';
import { WH } from '@components/WH';

export type ExternalRelinkItem = {
  domain?: string;
  tags?: ExternalRelinkTag[];
};

export type ExternalRelinkTag = {
  text?: string;
  target_url?: string;
};

type Props = {
  relink: ExternalRelinkItem[];
};

export const OurProjects = (props: Props) => {
  if (!props.relink || props.relink.length === 0) return null;

  return (
    <S.StyledOurProjects>
      <Wrapper>
        <S.OurProjectsContent>
          <S.OurProjectsTitle>
            <BlockTitle>Наши проекты</BlockTitle>
          </S.OurProjectsTitle>
          <S.OurPorjectItems count={props.relink.length}>
            {props.relink.map((p) => (
              <WH>
                <OurProject key={p.domain} {...p} />
              </WH>
            ))}
          </S.OurPorjectItems>
        </S.OurProjectsContent>
      </Wrapper>
    </S.StyledOurProjects>
  );
};
