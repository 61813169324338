import {
  OurProjectLink,
  OurProjectTags,
  StyledOurProject,
} from '../../elements/our-project/our-project.style';
import { device, scrollbar } from '@components/ui/theme';
import styled from 'styled-components';

export const OurProjectsContent = styled.div`
  display: flex;
`;

export const OurProjectsTitle = styled.div``;

export const OurPorjectItems = styled.div<{ count: number }>`
  width: 1148px;
  display: flex;
  grid-template-columns: repeat(${({ count }) => count}, 1fr);
  grid-column-gap: 20px;
  margin-left: auto;
`;

export const StyledOurProjects = styled.section`
  padding: 70px 0;
  width: 100%;
  background: #f6f5f7;

  @media (max-width: 1290px) {
    padding-top: 45px;
    padding-bottom: 55px;

    ${OurProjectsContent} {
      flex-direction: column;
    }

    ${OurProjectsTitle} {
      margin-bottom: 24px;
    }

    // Need to understand where and how to style scrollbar
    ${OurPorjectItems} {
      margin-left: 0;
      padding-bottom: 0;
      grid-template-columns: repeat(4, 270px);
      width: calc(100% + 60px);
      padding-left: 30px;
      padding-right: 30px;
      position: relative;
      left: -30px;
      overflow-x: auto;
      ${scrollbar.disable}
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    left: 0;

    ${OurPorjectItems} {
      width: calc(100% + 40px);
      left: -20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media ${device.mobileM} {
    padding: 35px 0;

    ${OurProjectsTitle} {
      margin-bottom: 25px;
      div {
        font-size: 20px;
      }
    }

    ${OurPorjectItems} {
      padding-bottom: 0;
      grid-template-columns: repeat(4, 212px);
      grid-column-gap: 16px;
      ${scrollbar.disable}
    }

    ${StyledOurProject} {
      padding: 15px 15px 20px 15px;

      ${OurProjectLink} a {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
      }

      ${OurProjectTags} {
        margin-top: 16px;
      }
    }
  }
`;
