import styled from 'styled-components';
import { StyledTag } from '@components/ui/tag/tag.style';

export const StyledOurProject = styled.article`
  background: #ffffff;
  border: 1px solid #f6f5f7;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 20px;
  flex-basis: 270px;
  min-width: 270px;

  &:hover {
    box-shadow: 0px 2px 12px rgba(99, 47, 117, 0.14);
  }

  @media (max-width: 480px) {
    flex-basis: 212px;
    min-width: 212px;

    ${StyledTag} {
      max-width: 182px;
    }
  }
`;

export const OurProjectLink = styled.div`
  a {
    font-size: 17px;
    line-height: 120%;
    text-decoration-line: underline;
    color: #3a373b;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const OurProjectTags = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  margin-top: 20px;

  ${StyledTag} {
    overflow: hidden;
  }
`;
